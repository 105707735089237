import {randomNumer} from "./randomNumber";

export const generateArray = (length: number, dimensions: number): any => {
    if (dimensions === 1) {
        return Array.from({length}, () => randomNumer());
    } else {
        return Array.from({length}, () =>
            generateArray(length, dimensions - 1)
        );
    }
}
