import React, {useCallback, useState} from 'react';
import {useExcelChangeLength} from "./hooks/useExcelChangeLength";

import HeaderApp from './components/Header';
import Row from './components/Row';

import {generateArray} from './tools/generateArray';

import './App.css';

export type ChangeCellValueProps = (
    row: number,
    cell: number,
    newValue: number
) => void

function App() {
    const [excelLength, setExcelLength] = useState<number>(4);
    const [excel, setExcel] = useState<number[][]>(
        generateArray(excelLength, 2)
    );

    useExcelChangeLength(excelLength, excel, setExcel)

    const changeCellValue: ChangeCellValueProps = useCallback((row, cell, newValue) => {
        setExcel(prevExcel => {
            const newExcel = [...prevExcel];
            newExcel[row][cell] = newValue;
            return newExcel;
        });
    }, []);

    const handleIncrementSize = () => {
        setExcelLength(prevExcelLength => prevExcelLength + 1);
    }

    const handleDecrementSize = () => {
        setExcelLength(prevExcelLength => {
            const newValue = prevExcelLength - 1;
            return newValue <= 0 ? 1 : newValue;
        });
    }

    const calcExcelSummary = (): string => {
        const flatExcel: number[] = excel.flat();
        const summary = flatExcel.reduce((summary, number) => {
            return summary + number;
        });
        return String(summary);
    }

    const rows = excel.map((rowValue, keyRow) => {
        return (
            <Row key={keyRow}
                 keyRow={keyRow}
                 rowValue={rowValue}
                 onChangeAction={changeCellValue}
            />
        )
    });

    // Wiem, powinno to pójść w kierunku prezentacyjnego komponentu
    return (
        <div className="App">
            <HeaderApp/>
            <main className="App_main">
                <div className="Table">
                    {rows}
                </div>
                <div className="App_Bottom">
                    <div>
                        <button
                            className="App_BottomBtn"
                            onClick={handleDecrementSize}>-
                        </button>
                        <span className="App_Length">
                        {excelLength}
                    </span>
                        <button
                            className="App_BottomBtn"
                            onClick={handleIncrementSize}>+
                        </button>
                    </div>

                    <div>
                        Razem: <strong>{calcExcelSummary()}</strong>
                    </div>
                </div>
            </main>

        </div>
    );
}

export default App;
