import Cell from "./Cell";
import {ChangeCellValueProps} from "../App";

import '../App.css';

interface RowProps {
    keyRow: number,
    rowValue: number[];
    onChangeAction: ChangeCellValueProps;
}

function Row(props: RowProps) {
    const {keyRow, rowValue, onChangeAction} = props;

    const cells = rowValue.map((cellValue, keyCell) => {
        return (
            <Cell key={`${keyRow}-${keyCell}`}
                  keyRow={keyRow}
                  keyCell={keyCell}
                  cellValue={cellValue}
                  onChangeAction={onChangeAction}
            />
        )
    });

    const rowSummary = rowValue.reduce((rowSummary, cellValue) => {
        return rowSummary + cellValue;
    });

    return (
        <div className="Row">
            {cells}
            {<div className="Row_summary">
                R: {rowSummary}
            </div>}
        </div>
    );
}

export default Row;
