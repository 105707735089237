import React from 'react';

function HeaderApp() {
    return (
        <header className="App_header">
            <h1>Excel</h1>
        </header>
    );
}

export default React.memo(HeaderApp);
