import {useEffect} from "react";
import {randomNumer} from '../tools/randomNumber';
import {generateArray} from '../tools/generateArray';

type useExcelChangeLengthInterface = (
    excelLength: number,
    excel: number[][],
    setExcel: React.Dispatch<React.SetStateAction<number[][]>>
) => void;

export const useExcelChangeLength: useExcelChangeLengthInterface = (
    excelLength,
    excel,
    setExcel
) => {
    useEffect(() => {
        const currentExcelLength = excel.length;
        if (currentExcelLength === excelLength) {
            return;
        }

        const increaseExcel = () => {
            setExcel(prevExcel => {
                const newRow = generateArray(excelLength, 1);
                prevExcel.forEach(row => {
                    row[excelLength - 1] = randomNumer();
                });
                return [...prevExcel, newRow];
            });
        }

        const reduceExcel = () => {
            setExcel(prevExcel => {
                const newExcel = [...prevExcel];
                newExcel.length = excelLength;
                newExcel.forEach(row => {
                    row.length = excelLength
                });
                return newExcel;
            });
        }

        if (excelLength < currentExcelLength) {
            reduceExcel();
        } else {
            increaseExcel();
        }

    }, [excelLength, excel.length, setExcel])
}
