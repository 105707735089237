import React, {useEffect, useId, useState} from 'react';
import {ChangeCellValueProps} from "../App";

import '../App.css';

interface CellProps {
    keyRow: number,
    keyCell: number,
    cellValue: number;
    onChangeAction: ChangeCellValueProps;
}

function Cell(props: CellProps) {
    const {keyRow, keyCell, cellValue, onChangeAction} = props;
    const [inputValue, setInputValue] = useState<string>(String(cellValue));

    useEffect(() => {
        if (String(cellValue) !== inputValue) {
            onChangeAction(keyRow, keyCell, Number(inputValue));
        }
    }, [inputValue, keyRow, keyCell, cellValue, onChangeAction]);

    const handlerChange = (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
        if (!event) {
            return;
        }

        const {value} = event.target;
        const newValue = value.slice(0, 3);

        setInputValue(newValue);
    }

    return (
        <input
            name={useId()}
            onChange={handlerChange}
            type="number"
            value={inputValue}
            className="Cell"
        />
    );
}

export default React.memo(Cell);
